import BasePlugin from '../BasePlugin'

export default class RouteConstructionCommand extends BasePlugin {
  async execute () {
    let map = this.context.getDashboardComponents()['component_9607ca04-12e4-47a2-8524-b1c9f60909ad'][0]
    let executor = this.context.getModel()['attr_2618_']

    let date = this.context.getModel()['attr_2385_']
    map.yMaps().geoObjects.removeAll()
    let errors = []

    if (!executor) {
      errors.push('<b>Исполнитель</b>')
    }
    if (!date) {
      errors.push('<b>Дата</b>')
    }
    if (errors.length > 0) {
      this.showError(`Не выбраны поля: ${errors.join(', ')}`)
      return false
    }
    let filters = [
      `attr_2545_,eq,${date}`,
      `attr_2544_,eqx,${executor}`
    ].map((item, index) => {
      return `filter[${index}]=${item}`
    })

    let data = await this.context.$http.get(
      `${this.context.$config.api}/registryservice/registry/2542/data?${filters.join('&')}`
    )

    if (data.data.count === 0) {
      this.showError('Нет данных')
      return false
    }

    const positionField = 'attr_2546_'
    let markers = []
    try {
      markers = JSON.parse(data.data.data[0][positionField]).filter((item) => item.status === 'LOCATION_UPDATED' || item.status === 'AVAILABLE').map((item) => {
        return { lat: item.lat, lon: item.lon, time: item.dateLocation }
      })
    } catch (e) {
      this.showError('Неккоректные данные')
      return false
    }
    if (markers.length === 0) {
      this.showError('Нет данных в маршруте')
      return false
    }
    console.log(markers)
    let points = []
    let lines = []
    markers.forEach((point, index, array) => {
      let pointHint = point.time ? this.context.$moment(new Date(point.time)).format('DD MMMM Y в H:mm:ss') : ''
      points.push(new window.ymaps.Placemark([point.lat, point.lon], {
        hintContent: pointHint
      }, {
        preset: 'islands#circleIcon',
        iconColor: '#409EFF'
      }))
      if (index > 0) {
        let previousPoint = array[index - 1]
        lines.push(new window.ymaps.GeoObject({
          // Описываем геометрию геообъекта.
          geometry: {
            // Тип геометрии - "Ломаная линия".
            type: 'LineString',
            // Указываем координаты вершин ломаной.
            coordinates: [[previousPoint.lat, previousPoint.lon], [point.lat, point.lon]]
          }
        }, {
          // Задаем опции геообъекта.
          // Включаем возможность перетаскивания ломаной.
          draggable: false,
          // Цвет линии.
          strokeColor: '#409EFF',
          // Ширина линии.
          strokeWidth: 5
        }))
      }
    })

    points.forEach((item) => map.yMaps().geoObjects.add(item))
    lines.forEach((item) => map.yMaps().geoObjects.add(item))

    map.yMaps().setBounds(map.yMaps().geoObjects.getBounds())
  }

  showError (text) {
    this.context.$message({
      type: 'error',
      dangerouslyUseHTMLString: true,
      message: text
    })
  }
}
